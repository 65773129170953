@font-face {
  font-family: "Roboto-bold";
  src: url("../../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lucky Fellas";
  src: url("../../fonts/LuckyFellas.woff2") format("woff2"),
    url("../../fonts/LuckyFellas.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Typo_Square_Bold_Demo";
  src: url("../../fonts/Typo_Square_Bold Demo.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.banner {
  background-image: url("https://resources.swmc.com/swmc-images/WholesaleLending/bannerbG.svg");
  height: 450px;
  background-position: center;
  max-width: 100%;
  position: relative;
}

.banner .leftdot {
  position: absolute;
  left: 33%;
  top: 0;
  width: 70px;
  opacity: 0.7;
}

.banner .rightdot {
  position: absolute;
  right: 5%;
  top: 20%;
  width: 50px;
}

.banner .rightcurve {
  position: absolute;
  right: 12%;
  bottom: 2%;
  z-index: -1;
  width: 130px;
}

.banner h1 {
  font-family: "Lucky Fellas";
  font-size: 100px;
  color: #fff;

  font-weight: 500;
  line-height: 95px;
  position: absolute;
  left: 25%;
  top: 22%;
}
.banner h1 div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin-left: -1rem;
  margin-top: -1.5rem;
}
.bannerCircle {
  background: #fff;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0px 30px 63px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 15%;
  top: 10%;
  width: 400px;
  height: 400px;
}

.bannerInnerCircle {
  background: #97daf1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 15px solid #97daf1;
  background-image: url("https://resources.swmc.com/swmc-images/WholesaleLending/wholesale.svg");
  background-size: contain;
}
@media (max-width: 1250px) {
  .bannerCircle {
    right: 12%;
    top: 10%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .banner h1 {
    font-size: 100px;
    left: 15%;
    top: 22%;
  }
}

@media (min-width: 850px) and (max-width: 1023px) {
  .banner h1 {
    font-family: "Lucky Fellas";
    font-size: 85px;
    color: #fff;

    font-weight: 500;
    line-height: 80px;
    position: absolute;
    left: 15%;
    top: 28%;
  }
  .bannerCircle {
    right: 12%;
    top: 20%;
    width: 300px;
    height: 300px;
  }
  .banner h1 div {
    font-size: 13px;
    margin-left: -1.3rem;
  }
}

@media (min-width: 768px) and (max-width: 849px) {
  .banner {
    height: 420px;
  }
  .banner h1 {
    font-family: "Lucky Fellas";
    font-size: 85px;
    color: #fff;

    font-weight: 500;
    line-height: 80px;
    position: absolute;
    left: 15%;
    top: 28%;
  }
  .banner h1 div {
    font-size: 13px;
    margin-left: -1.3rem;
  }
  .bannerCircle {
    right: 8%;
    top: 20%;
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  .banner {
    height: 480px;
  }
  .banner h1 {
    font-size: 75px;

    font-weight: 500;
    line-height: 80px;
    left: 32%;
    top: 4%;
  }

  .banner h1 div {
    font-size: 13px;
    margin-left: -2rem;
  }
  .bannerCircle {
    left: 32%;
    top: 45%;
    width: 250px;
    height: 250px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .banner {
    height: 500px;
    overflow: hidden;
    background-image: none;
  }
  .banner::before {
    content: "";
    position: absolute;
    width: 400%;
    height: 400%;
    top: -98%;
    left: -100%;
    z-index: -1;
    background: url("https://resources.swmc.com/swmc-images/WholesaleLending/bannerbG.svg")
      0 0 repeat;
    transform: rotate(333deg);
  }
  .banner h1 {
    font-size: 80px;
    width: 100%;
    font-weight: 500;
    line-height: 70px;
    left: 0;
    top: 8%;
    text-align: center;
    padding: 0 10px;
  }
  .banner h1 div {
    font-size: 13px;
    margin-left: 0;
  }
  .bannerCircle {
    width: 260px;
    height: 260px;
    left: 27%;
    top: 45%;
    box-shadow: 0px 10px 63px rgba(0, 0, 0, 0.15);
  }

  .banner .leftdot,
  .banner .rightdot {
    display: none;
  }
}
@media (min-width: 401px) and (max-width: 450px) {
  .bannerCircle {
    left: 22%;
    top: 45%;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .banner h1 div {
    font-size: 12px;
  }
  .bannerCircle {
    right: 9%;
    left: 14%;
  }
}

@media (max-width: 1023px) {
  .rightcurve {
    display: none;
  }
}
@media (min-width: 991.7px) {
  .UserWayAccessibilitydesktopmobile {
    display: none;
  }
}


.UserWayAccessibilitydesktopmobile {
  padding-top: 0rem;
  padding-bottom: 3rem;
}
@media (max-width: 991.7px) {
.bannercontent {
  margin-top: 3rem;
}
}