.pageContainer {
  margin-top: 80px;
}
.priceButton {
  background-color: #083f88 !important;
  position: relative;
  display: block;
  margin: -1rem 0 1.5rem 0;
  font-family: inherit;
}
@media screen and (min-width: 768px) and (max-width: 992.9px) {
  .form1 {
    width: 600px !important;
  }
}
@media screen and (min-width: 992px) {
  .form1 {
    width: 600px !important;
  }
}
.form1 {
  max-width: 600px;
  padding: 30px;
  margin: 20px;
  background: rgb(255, 255, 255);
  border: 2px solid rgb(8, 63, 136);
  box-sizing: border-box;
  box-shadow: rgb(0 170 228 / 20%) 0px 0px 30px 10px;
  border-radius: 10px;
}
.primaryh2,
.dynamicHeading {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #00aae4;
}
.dynamicHeading {
  color: #083f88;
}

.sunWestIntroRow,
.partnerRow,
.productRow,
.loanStatusRow,
.guidelineRow,
.contactRow,
.brokerAppRow {
  justify-content: center;
}
.dynamicRow {
  display: flex;
  justify-content: center;
}
.sunWestIntroRow,
.partnerRow,
.productRow,
.guidelineRow,
.dynamicRow {
  padding: 20px 70px !important;
}

.sunWestIntroRow p,
.dynamicRow p {
  font-weight: 600;
  font-size: 16px;
  text-align: justify;
  color: #083f88;
}

.partnerRow {
  margin-bottom: 20px;
}

.partnerRow h3 {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #083f88;
}

.introSection #clickLink {
  cursor: pointer;
  color: #007bff;
}

.introSection #clickLink:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #0056b3;
}

.partnerRow ul li {
  font-weight: 600;
  font-size: 18px;
  color: #083f88;
}

.partnerRow .partnerRectangle {
  /* max-width: 1173px; */
  background: #ffffff;
  border: 4px solid #00aae4;
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(0, 170, 228, 0.3);
  border-radius: 51px;
  margin: 20px 0 30px 0;
  padding: 20px;
}
.partnerRow p {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #083f88;
}

.productRow h2 {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #083f88;
}

.productRow h3 {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: #00aae4;
}

.loanStatusRow {
  background-image: url("https://resources.swmc.com/swmc-images/WholesaleLending/wholsale-mask.jpg");
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 30px;
}
.loanStatusRow input {
  margin-left: -3px;
}
.loanStatusRow h2 {
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  color: #fff;
}

.loanStatusRow h3 {
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.productCard {
  background: #e6f7fd;
  border-radius: 28px;
  text-align: center;
  padding: 10px;
  max-width: 180px;
  margin-bottom: 20px;
  height: 170px;
  border: 2px solid transparent;
  margin-inline: auto;
}
.productCard img {
  width: 100px;
  padding: 10px;
  height: 100px;
}
.productCard h5 {
  text-align: center;
  color: #083f88;
  font-weight: 700;
}
.productCard:hover {
  border: 2px solid #083f88;
}

.cardDiv {
  padding: 20px 60px;
}

.cardDiv > div {
  justify-content: center;
  display: flex;
}

.accessRateDiv,
.rateSheetDiv {
  background: #083f88;
  border-radius: 20px;
  padding: 20px 0 0 0;
  border: 2px solid #083f88;
  margin-bottom: 20px;
}

.accessRateDiv .accessRateContent,
.rateSheetDiv .rateSheetContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 20px 20px 10px 20px;
  border-radius: 20px;
  min-height: 320px;
}

.accessRateDiv .accessRateContent h6,
.rateSheetDiv .rateSheetContent h6 {
  text-align: center;
  font-weight: 600;
  color: #083f88;
  font-size: 16px;
}
.accessRateDiv .accessRateContent small {
  text-align: center;
  font-weight: 600;
  color: #083f88;
  font-size: 14px;
}

.accessButton {
  background: #00aae4;
  border: 2px solid #00aae4;
  box-sizing: border-box;
  border-radius: 20px;
  width: 200px;
  padding: 3px 5px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.accessRateDiv h3,
.rateSheetDiv h3 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #fff;
}

.rateSheetDiv h4 {
  font-weight: 700;
  font-size: 26px;
  text-align: center;
  color: #083f88;
  margin-bottom: 20px;
}
.rateSheetDiv .customRatesLabel {
  font-size: 16px;
  font-weight: 600;
  color: #083f88;
}

.rateSheetDiv .ratesSmallFont p {
  font-size: 10px !important;
  color: #083f88 !important;
  text-align: left !important;
}

.contactRow {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
  margin-bottom: 20px;
  /* background-image: linear-gradient( rgba(12, 60, 118, 0.1), rgba(0, 170, 228, 0.5)),url('https://resources.swmc.com/swmc-images/Contact_us/Contact_Us_min.jpg'); */
  background-image: linear-gradient(
      90deg,
      rgba(0, 170, 228, 0.4962359943977591) 100%,
      rgba(0, 170, 228, 0.4066001400560224) 100%,
      rgba(180, 187, 230, 0.24413515406162467) 100%
    ),
    url("https://resources.swmc.com/swmc-images/Contact_us/Contact_Us_min.jpg");
}

.contactRow h2 {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #fff;
}

.contactRow p {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
}

.contactRow hr {
  border-top: 2px solid #fff;
  width: 70%;
}
.contactRow .contactButton {
  background: #083f88;
  border: 2px solid #083f88;
  box-sizing: border-box;
  border-radius: 20px;
  width: 200px;
  padding: 3px 5px;
  color: #fff;
  margin: 10px;
  font-weight: 600;
}

.brokerAppRow {
  flex-direction: column;
  align-items: center;
}

.brokerAppRow h2 {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #083f88;
}

.brokerAppRow h3 {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: #083f88;
}

.introSection,
.productSection,
.guidelineSection,
.brokerformSection {
  position: relative;
  font-family: "Poppins";
}
.rightbluebg {
  z-index: -1;
}
.introSection .rightbluebg {
  position: absolute;
  right: 0;
  max-width: 200px;
  top: 0;
}

.introSection .leftbluebg {
  position: absolute;
  left: 0;
  max-width: 200px;
  top: 0;
  transform: rotate(180deg);
}

.introSection .leftdot {
  position: absolute;
  left: 0;
  top: 80%;
  width: 50px;
  opacity: 0.3;
}

.introSection .rightcurve {
  position: absolute;
  right: 0;
  top: 50%;
  width: 80px;
  transform: rotate(82deg);
}

.productSection .rightbluebg {
  position: absolute;
  right: 0;
  max-width: 250px;
  bottom: 0;
}

.productSection .leftbluebg {
  position: absolute;
  left: 0;
  max-width: 200px;
  top: 0;
  transform: rotate(180deg);
}

.productSection .leftdot {
  position: absolute;
  right: 0;
  top: 30%;
  width: 50px;
  opacity: 0.3;
}

.productSection .rightcurve {
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 80px;
  transform: rotate(82deg);
}
.productSection .bluesemidot {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.guidelineSection .rightbluebg {
  position: absolute;
  right: 0;
  max-width: 250px;
  bottom: 0;
}

.guidelineSection .leftbluebg {
  position: absolute;
  left: 0;
  max-width: 200px;
  top: 0;
  transform: rotate(180deg);
}

.guidelineSection .leftdot {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  opacity: 0.3;
}

.brokerformSection {
  background-image: url("https://resources.swmc.com/swmc-images/WholesaleLending/blueBottombg.svg");
}

.brokerformSection .rightcurve {
  position: absolute;
  right: 0;
  top: 15%;
  width: 60px;
  transform: rotate(180deg);
}
.brokerformSection .bluesemidot {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  transform: rotate(180deg);
}

.eSquare,
.ellipseMobile,
.ellipseMobilemid,
.ellipseMobiletop,
.eSquarebottom,
.eSquaremid,
.eSquaretop {
  display: none;
}
.dynamicRow * {
  font-family: "Poppins";
}
.dynamicRow span {
  font-weight: bold;
  display: inline-block;
  font-size: calc(16px);
}
.dynamicRow :where(ul, li) {
  color: #083f88;
  font-family: inherit;
  font-size: inherit;
}

.fico {
  background: #083f88;
  border-radius: 16px;
  width: fit-content;
  font-family: "Poppins";
  font-size: 25px;
  text-align: center;
  color: #ffffff;
  padding: 0.35rem 1rem;
  margin-bottom: 1rem;
}
/* media queries for the page except banner */

@media (max-width: 1199px) {
  .accessRateDiv h3,
  .rateSheetDiv h3 {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #fff;
    min-height: 77px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .sunWestIntroRow,
  .partnerRow,
  .productRow,
  .guidelineRow,
  .dynamicRow {
    padding: 20px 20px !important;
  }
  .cardDiv {
    padding: 20px 20px;
  }
  .productCard h5 {
    text-align: center;
    color: #083f88;
    font-weight: 700;
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .accessRateDiv .accessRateContent,
  .rateSheetDiv .rateSheetContent {
    min-height: 340px;
  }
}

@media (min-width: 320px) and (max-width: 330px) {
  .form1 {
    width: 300px;
  }
}
@media (min-width: 310px) and (max-width: 450px) {
  .productCard img {
    width: 90px;
    padding: 10px;
    height: 90px;
  }

  .productCard {
    height: 160px;
    padding: 5px;
  }
  .productCard h5 {
    font-size: 15px;
  }

  .loanStatusRow {
    flex-direction: inherit;
  }

  .loanStatusRow h2 {
    font-size: 32px;
  }

  .loanStatusRow h3 {
    font-weight: 700;
    font-size: 24px;
  }

  .primaryh2,
  .dynamicHeading {
    font-weight: 700;
    font-size: 24px;
  }

  .partnerRow .partnerRectangle {
    margin-top: 0;
  }

  .partnerRow {
    margin: 10px 20px !important;
    padding-top: 0 !important;
  }
  .partnerRow h3 {
    font-weight: 600;
    font-size: 18px;
  }

  .partnerRow ul li {
    font-size: 16px;
  }

  .partnerRow p {
    font-size: 16px;
  }

  .productRow h2 {
    font-size: 24px;
  }

  .productRow h3 {
    font-size: 18px;
  }

  .accessRateDiv h3,
  .rateSheetDiv h3 {
    font-size: 18px;
    min-height: auto;
  }

  .brokerAppRow h2 {
    font-size: 24px;
  }

  .brokerAppRow h3 {
    font-size: 18px;
  }

  .contactRow h2 {
    font-size: 24px;
  }

  .contactRow p {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  .introSection .rightbluebg,
  .introSection .leftbluebg,
  .introSection .leftdot,
  .introSection .rightcurve,
  .productSection .rightbluebg,
  .productSection .leftbluebg,
  .productSection .leftdot,
  .productSection .rightcurve,
  .productSection .bluesemidot,
  .guidelineSection .rightbluebg,
  .guidelineSection .leftbluebg,
  .guidelineSection .leftdot,
  .brokerformSection .rightcurve,
  .brokerformSection .bluesemidot {
    display: none;
  }

  .introSection .eSquare {
    position: absolute;
    bottom: 0;
    left: 45%;
  }

  .introSection .ellipseMobile {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 99px;
  }

  .productSection .eSquare {
    position: absolute;
    bottom: 0;
    left: 45%;
  }

  .productSection .ellipseMobile {
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 90px;
  }

  .guidelineSection .eSquaretop {
    position: absolute;
    top: 0;
    right: 5%;
  }

  .guidelineSection .ellipseMobiletop {
    position: absolute;
    top: 0;
    left: 45%;
    width: 90px;
  }

  .guidelineSection .eSquaremid {
    position: absolute;
    top: 28%;
    left: 20%;
  }

  .guidelineSection .ellipseMobilemid {
    position: absolute;
    top: 25%;
    left: 0;
    width: 85px;
  }

  .guidelineSection .eSquarebottom {
    position: absolute;
    bottom: 2%;
    right: 5%;
  }

  .accessRateDiv {
    margin-top: 25px;
  }

  .eSquare,
  .ellipseMobile,
  .ellipseMobilemid,
  .ellipseMobiletop,
  .eSquarebottom,
  .eSquaremid,
  .eSquaretop {
    display: block;
  }
}
