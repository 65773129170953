.inputStyle{
    border-left:2px solid #1d90cc !important;
    border-right:2px solid #1d90cc !important;
    width: 100% !important;
    box-shadow: 0 0 1px 0 rgba(163, 206, 214, 0.82),0 1px 1px 0 rgba(163, 206, 214, 0.82);
    display: block;
    height: calc(1.5em + .75rem + 2px) !important;
    margin-top:5px !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.inputStyle::placeholder {
  
    opacity: 1; /* Firefox */
    text-transform:capitalize;
  }
  
  .inputStyle:-ms-input-placeholder { /* Internet Explorer 10-11 */
   text-transform:capitalize;
  }
  
  .inputStyle ::-ms-input-placeholder { /* Microsoft Edge */
   text-transform:capitalize;
  }

.textBoxStyle{
        border-left:2px solid #1d90cc !important;
        border-right:2px solid #1d90cc !important;
        width: 100% !important;
        box-shadow:0 0 1px 0 rgba(163, 206, 214, 0.82),0 1px 1px 0 rgba(163, 206, 214, 0.82);
        display: block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}